import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _random from 'lodash.random'
import {
  XYPlot,
  XAxis,
  YAxis,
  MarkSeries,
  HorizontalGridLines,
  VerticalGridLines,
  LineMarkSeries,
  GradientDefs,
  LineSeries,
  CustomSVGSeries,
} from 'react-vis'
import Polygon from 'react-svg-polygon'

import themes from '../themes.json'

class Plot extends Component {
  calcAngleDegrees(p1, p2) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/atan2
    // https://gist.github.com/conorbuck/2606166
    return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI
  }

  gradientDefs() {
    let themeList = this.props.themes

    return (
      <GradientDefs>
        {themeList.map((themeObj, index) => {
          let id = `gradient-theme-${index}`

          return (
            <linearGradient key={id} id={id} x1="0" x2="0" y1="0" y2="1">
              <stop
                offset="10%"
                stopColor={themeObj.colors[0]}
                stopOpacity={1.0}
              />
              <stop
                offset="90%"
                stopColor={themeObj.colors[1]}
                stopOpacity={1.0}
              />
            </linearGradient>
          )
        })}
      </GradientDefs>
    )
  }

  render() {
    const props = this.props
    const state = this.state
    let themeList = props.themes

    let backgroundLineSeries = []
    let modifiedData = props.data.slice(0)
    let prevObj = null
    let customSVGDaga = []

    if (props.data.length >= 2) {
      modifiedData.forEach((item, index) => {
        if (index % 2 === 0 && item && props.data[index + 1]) {
          let fakeCurrentDataThemeIndex = themeList[index / 2] ? index / 2 : 0
          let theme = themeList[fakeCurrentDataThemeIndex]

          backgroundLineSeries.push(
            <LineSeries
              key={`LineSeries_${index}_theme_${fakeCurrentDataThemeIndex}`}
              strokeWidth={24}
              themeIndex={fakeCurrentDataThemeIndex}
              color={`url(#gradient-theme-${fakeCurrentDataThemeIndex})`}
              data={[item, props.data[index + 1]]}
              style={{
                zIndex: index * 2,
                // strokeLinecap: 'square',
              }}
              size={12}
            />
          )
        }
      })

      modifiedData.map((obj, index) => {
        console.log('index', index)
        let nextObj = modifiedData[index - 1]
        let lineComponent = backgroundLineSeries[Math.floor(index / 2)]

        obj.fill = obj.fill || 'black'
        if (
          nextObj &&
          typeof nextObj !== 'undefined' &&
          backgroundLineSeries.length >= 1 &&
          lineComponent
        ) {
          let themeIndex = lineComponent.props.themeIndex
          let theme = themeList[themeIndex]
          console.log('theme', themeIndex, theme)
          // let nextTheme = nextObj.theme ??

          obj.sides = theme.sides

          if (index % 2 !== 0) {
            let angle = this.calcAngleDegrees(obj, nextObj)
            console.log('---')
            console.log('📐 angle', angle)
            console.log('---')

            let anglePositive = angle * -1

            obj.angle = angle
            nextObj.angle = angle

            // obj.size = anglePositive / 90 + 10
            // nextObj.size = anglePositive / 90 + 10
          }

          if ((index + 1) % 2 === 0) {
            // Second shape
            // TODO Change to switch or something else
            if (nextObj.x >= obj.x && nextObj.y >= obj.y) {
              obj.fill = theme.colors[1]
              nextObj.fill = theme.colors[0]
            } else if (nextObj.x <= obj.x && nextObj.y <= obj.y) {
              obj.fill = theme.colors[0]
              nextObj.fill = theme.colors[1]
            } else if (nextObj.x < obj.x && nextObj.y > obj.y) {
              obj.fill = theme.colors[1]
              nextObj.fill = theme.colors[0]
            } else if (nextObj.x > obj.x && nextObj.y < obj.y) {
              obj.fill = theme.colors[0]
              nextObj.fill = theme.colors[1]
            }
          }
        }
      })
    }

    modifiedData.forEach((obj, index) => {
      let size = obj.size || 14
      let newData = Object.assign({}, obj, {
        customComponent: (row, positionInPixels) => {
          let sides = obj.sides || 8
          let tail = null

          return (
            <g
              className="CustomSVGSeriesCustomComponent"
              style={{
                transform: `rotate(${obj.angle}deg)`,
              }}>
              <g style={{ transform: `translate(-${size}px, -${size}px)` }}>
                <Polygon sides={sides} fill={row.fill} r={size} />
                {/*
              <text x={0} y={0}>
                <tspan x="0" y="0">{`x: ${positionInPixels.x}`}</tspan>
                <tspan x="0" y="1em">{`y: ${positionInPixels.y}`}</tspan>
                <tspan x="0" y="2em">{`angle: ${obj.angle}`}</tspan>
              </text>
              */}
              </g>
            </g>
          )
        },
        // size: size,
        // style: {
        //   transform: `translate(0, -${size / 2}px)`,
        //   // fill: `url(#gradient-1)`,
        //   fill: obj.fill || 'black',
        // },
      })

      customSVGDaga.push(newData)
    })

    return (
      <div
        className="absolute top-0 left-0 z0"
        style={{
          pointerEvents: 'none',
          width: '100%',
          marginTop: `-${props.margin.top}px`,
          paddingRight: `${props.margin.right}px`,
          paddingBottom: `${props.margin.bottom}px`,
          marginLeft: `-${props.margin.left}px`,
        }}>
        <XYPlot
          margin={props.margin}
          width={props.width}
          height={props.height}
          onClick={e => {
            // console.log(e)
          }}
          onMouseMove={e => {
            // console.log(e.clientX, e.clientY)
            // This does what you’d expect and shows you the change
            // console.log('movement', e.movementX, e.movementY)
          }}>
          {this.gradientDefs()}
          <HorizontalGridLines />
          <VerticalGridLines />
          {backgroundLineSeries}
          <MarkSeries
            color="transparent"
            data={[
              // Adds default bottom left and top right points,
              // so the scale is set at the zoom level we want
              { x: 0, y: 0, opacity: '0' },
              { x: props.xMax, y: props.yMax, opacity: '0' },
            ]}
          />
          <CustomSVGSeries data={customSVGDaga} />
          <XAxis title="X" />
          <YAxis />
        </XYPlot>
      </div>
    )
  }
}

Plot.defaultProps = {
  xMax: 10,
  yMax: 10,
  width: 500,
  height: 350,
  margin: { left: 40, right: 10, top: 10, bottom: 40 },
  themes: themes,
}

Plot.propTypes = {
  data: PropTypes.array.isRequired,
  xMax: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  margin: PropTypes.object.isRequired,
  themes: PropTypes.array.isRequired,
  // themeIndex: PropTypes.number.isRequired,
}

export default Plot
