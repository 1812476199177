import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _random from 'lodash.random'
import Wrapper from './Wrapper'

class AudioWrapper extends Component {
  constructor() {
    super()

    this.state = {
      id: this.getRandomId(),
    }

    this.handleChangeAudio = this.handleChangeAudio.bind(this)
  }

  getRandomId() {
    let newId = _random(1, 3)

    while (this.state && this.state.id && newId === this.state.id) {
      newId = _random(1, 3)
    }

    return newId
  }

  async audioPlay() {
    let el = this.audioElement

    try {
      el.load()
      await el.play()
    } catch (err) {
      console.error(err)
    }
  }

  async audioPause(cb) {
    let el = this.audioElement

    try {
      await el.pause()
    } catch (err) {
      console.error(err)
    }
  }

  handleChangeAudio() {
    this.audioPause()
      .then(() => {
        console.log('pause')
        return this.setState({
          id: this.getRandomId(),
        })
      })
      .then(() => {
        console.log('then')
        window.setTimeout(() => {
          this.audioPlay()
        }, 1000)
      })
  }

  componentDidMount() {
    if (this.props.autoPlay === true) {
      this.audioPlay()
    }
  }

  componentWillUnmount() {
    this.audioPause()
  }

  render() {
    const props = this.props
    let audioSource = `/demo-audio/${this.state.id}.m4a`

    return (
      <div className="bg-silver" style={{ borderRadius: '999px' }}>
        <div className="max-width-5 mx-auto">
          <div className="flex items-center">
            <audio
              className="col-12 mr2"
              controls
              autoPlay={props.autoPlay}
              ref={el => {
                this.audioElement = el
              }}>
              <source src={audioSource} type="audio/mp4" />
              Your browser does not support the
              <code>audio</code> element.
            </audio>
            <div className="h6 my1">
              <code>{audioSource}</code>&nbsp;
              <button
                className="btn p0 blue bold"
                onClick={this.handleChangeAudio}>
                Randomize Audio
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AudioWrapper.defaultProps = {
  autoPlay: false,
}

AudioWrapper.propTypes = {
  autoPlay: PropTypes.bool.isRequired,
}

export default AudioWrapper
