import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

const ButtonPanel = props => {
  return (
    <div className="md-flex justify-between p1 mxn1">
      <div className="px1 col-12 mb2 order-1 md-mb0 md-col-8">
        {props.children}
      </div>
      <div className="px1 inline-block order-0 col-6 md-col-2">
        {props.prev ? (
          <Link to={props.prev} className="col-12 center btn py2 px3">
            ← Back
          </Link>
        ) : null}
      </div>
      <div className="px1 inline-block order-last col-6 md-col-2">
        {props.next ? (
          <Link
            to={props.next}
            className="col-12 center btn btn-primary py2 px3">
            Next →
          </Link>
        ) : null}
      </div>
    </div>
  )
}

ButtonPanel.defaultProps = {
  next: null,
  prev: null,
  children: null,
}

ButtonPanel.propTypes = {
  next: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  prev: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
}

export default ButtonPanel
