import React from 'react'

class Start extends React.Component {

  render() {
    return (
      <div className="start-wrapper">
        <div className="soundshape-background"></div>
        <ul className="partner-logos">
          <li className="open-university" >
            <img src="images/the-open-university-logo.svg" alt="The Open University logo."/>
          </li>
          <li className="tate-exchange">
            <img src="images/tate-exchange-logo.svg" alt="Tate Exchange logo."/>
          </li>
          <li className="counterpoints">
            <img src="images/counterpoints-logo.svg" alt="Counterpoints Arts logo."/>
          </li>
        </ul>
          <picture className="start-background-image">
            <source srcSet="images/soundshape-background.webp" type="image/webp"/>
            <source srcSet="images/soundshape-background.jpg" type="image/jpeg"/> 
            <img src="images/soundshape-background.jpg" alt="Multicoloured polygon shape."/>
          </picture>
          <div className="title-wrapper">
            <h1 className="h2 line-height-1">Open University Soundshapes</h1>
            <p className="h3">
              You are about to be guided through a landscape of languages and you'll
              be asked to judge them. Do you recognise the language? Do you like the language?
              Along the way we’ll teach you a little more about what they mean and where they come from.
            </p>
          </div>
      </div>
    )
  }
}

export default Start