import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Helmet } from 'react-helmet'
import { Router, Link, Location } from '@reach/router'
import Start from './components/Start'
import AboutOverlay from './components/AboutOverlay'
import ControlPad from './components/ControlPad'
import AudioPlayer from './components/AudioPlayer'
import ButtonPanel from './components/ButtonPanel'
import Wrapper from './components/Wrapper'

const PageHome = props => {
  return (
    <Wrapper>
      <Start/>
    </Wrapper>
  )
}

const PageStep1 = props => {
  return (
    <Wrapper>
      <h1 className="h2 line-height-1">Step 1</h1>
      <p>
        Listen to the clip. This is a demonstration of how the audio can start
        automatically playing when you get to this step. Because this is a
        client-side application, we can keep playing and controlling the audio
        without interuption, even when you move to the{' '}
        <Link to="/step-2">next step</Link>.
      </p>
      <p>
        Public domain audio clip{' '}
        <a href="https://archive.org/details/MLKDream">Source 1</a>,{' '}
        <a href="https://archive.org/details/Saad_AlGamidi/055.mp3">
          Source 2 and Source 3
        </a>
        .
      </p>
    </Wrapper>
  )
}

const PageStep2 = props => {
  return (
    <Wrapper>
      <h1 className="mt0 h2 line-height-1">Step 2</h1>
      <ControlPad
        onClickSubmit={data => {
          console.log(
            'Would handle form submission to Craft backend here:',
            data
          )

          if (window && window.fetch) {
            let url = '/backend'
            window
              .fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              })
              .then(function(res) {
                if (res) {
                  // Parses JSON response into native Javascript objects
                  // Ex. if there was an error that came back from the form
                  // Right now you’ll get a 404 because the backend URL doesn’t exit
                  if (res.status === 200) {
                    console.log('Success! Returned JSON from the server')
                    let result = res.json()
                    console.log(result)
                  } else {
                    console.log(
                      `Unsuccessful, returned ${res.status} status code`
                    )
                    console.log(res)
                  }
                }
              })
              .catch(function(err) {
                console.warn('Error:', err)
              })
          }
        }}
      />
    </Wrapper>
  )
}

const AppWrapper = props => {
  return (
    <div style={{ height: '100%', minHeight: '100vh' }}>
      <AboutOverlay />
      {props.children}
    </div>
  )
}

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

// For now, this is where I’m storing the steps, so I can use
// them to generate the next and previous page links.
let steps = ['/', '/step-1', '/step-2']

class App extends Component {
  render() {
    // Next and previous links sit outside the Router,
    // so the component stays the same regardless of what
    // view you’re on.
    let footerNextPrevLinks = (
      <Location>
        {({ location }) => {
          let index = steps.indexOf(location.pathname)
          let next = steps.length - 1 >= index + 1 ? steps[index + 1] : false
          let prev = index - 1 >= 0 ? steps[index - 1] : false

          return (
            <div
              className="sticky z4 bottom-0 col-12 bg-white p2"
              style={{
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
              }}>
              {index === 0 ? (
                <div className="center">
                  <Link
                    to={next}
                    className="btn btn-primary bg-white black border border-black regular">
                    Start
                  </Link>
                </div>
              ) : (
                <ButtonPanel next={next} prev={prev}>
                  <AudioPlayer />
                </ButtonPanel>
              )}
            </div>
          )
        }}
      </Location>
    )

    return (
      <div className="App">
        <AppWrapper>
          <Router>
            <PageHome path="/" />
            <PageStep1 path="step-1" />
            <PageStep2 path="step-2" />
          </Router>
        </AppWrapper>
        {footerNextPrevLinks}
      </div>
    )
  }
}

export default App
