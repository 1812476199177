import 'react-vis/dist/style.css'

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactCursorPosition from 'react-cursor-position'
import Plot from './Plot'

class ControlPadInner extends Component {
  constructor() {
    super()

    this.state = {
      x: 0,
      y: 0,
    }
  }

  convertCoordinates(data) {
    const props = this.props
    let yInverted = props.elementDimensions.height - data.y

    let result = {
      x: ((data.x * props.xMax) / props.elementDimensions.width).toFixed(2),
      y: ((yInverted * props.yMax) / props.elementDimensions.height).toFixed(2),
    }

    return result
  }

  render() {
    const props = this.props
    const state = this.state

    return (
      <div>
        <div
          className="absolute top left p3 z2"
          style={{ pointerEvents: 'none' }}>
          <div>
            <strong>Click</strong> {state.x}, {state.y}
          </div>
          <div>
            <strong>Hover</strong> {props.position.x}, {props.position.y}
          </div>
        </div>
        <div
          className="col-12"
          style={{ height: `${props.elementDimensions.height}px` }}
          onClick={e => {
            let newState = {
              x: props.position.x,
              y: props.position.y,
            }
            newState = this.convertCoordinates(newState)
            this.setState(newState)
            return this.props.onClick(newState)
          }}
        />
      </div>
    )
  }
}

ControlPadInner.defaultProps = {}

ControlPadInner.propTypes = {
  xMax: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  getElementDimensions: PropTypes.func,
}

class ControlPad extends Component {
  constructor(props) {
    super(props)

    this.state = {
      parentWidth: props.defaultWidth,
      parentHeight: props.defaultHeight,
      data: [],
    }

    this.handleGetParentDimensions = this.handleGetParentDimensions.bind(this)
  }

  handleGetParentDimensions() {
    if (this.parent) {
      let rect = this.parent.getBoundingClientRect()
      this.setState({
        parentWidth: rect.width,
        parentHeight: rect.height,
      })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.handleGetParentDimensions()
    })

    this.resize = window.setTimeout(() => {
      return this.handleGetParentDimensions()
    }, 100)
  }

  componentWillUnmount() {
    window.clearTimeout(() => this.resize)
  }

  render() {
    const props = this.props
    const state = this.state

    return (
      <Fragment>
        <details>
          <summary>Show JSON</summary>
          <pre>{JSON.stringify(state.data, 0, 2)}</pre>
        </details>
        <div className="relative" ref={el => (this.parent = el)}>
          <ReactCursorPosition
            className="rounded black col-12 mx-auto relative z3"
            style={{
              cursor: 'pointer',
              width: '100%',
              height: '70vh',
            }}>
            <ControlPadInner
              onClick={newData => {
                let copyData = state.data.slice(0)
                copyData.push(newData)
                this.setState({ data: copyData })
              }}
              xMax={props.xMax}
              yMax={props.yMax}
            />
          </ReactCursorPosition>
          <Plot
            data={state.data}
            xMax={props.xMax}
            yMax={props.yMax}
            margin={props.margin}
            width={state.parentWidth + props.margin.left + props.margin.right}
            height={state.parentHeight + props.margin.top + props.margin.bottom}
          />
        </div>
        <div className="mt3">
          <button
            className="btn btn-primary px3 py2"
            onClick={e => {
              props.onClickSubmit(state.data)
            }}>
            Example Submit Vote
          </button>
        </div>
      </Fragment>
    )
  }
}

ControlPad.defaultProps = {
  xMax: 10,
  yMax: 10,
  defaultWidth: 500,
  defaultHeight: 350,
  margin: { left: 40, right: 10, top: 10, bottom: 40 },
}

ControlPad.propTypes = {
  xMax: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  defaultWidth: PropTypes.number.isRequired,
  defaultHeight: PropTypes.number.isRequired,
  margin: PropTypes.object.isRequired,
  onClickSubmit: PropTypes.func,
}

export default ControlPad
