// Import the React library from the npm package react
import React from 'react'

// Minimal React component
class AboutOverlay extends React.Component {
  // Generally, the things you need to do to initially setup
  // the component. You can probably ignore this part for now
  constructor() {
    super()

    this.state = {
      drawerOpen: false,
    }

    // Bind this to our custom toggle
    // function (defined further down)
    // You use this pattern if you need `this`
    // inside that function, and we do so we can use
    // this.setState() to toggle `this.state.drawerOpen`
    this.toggleDrawer = this.toggleDrawer.bind(this)
  }

  // Built-in React component function
  componentDidMount() {
    // When the component loaded in the browser
    console.log('Mounted in the browser!')

    // If you need to do things based on window or other
    // JavaScript libraries that are not related to React, you
    // will often do them here, once the component mounted.
  }

  // Our custom toggle function
  toggleDrawer(e) {
    // The click event
    console.log(e)

    this.setState({
      drawerOpen: !this.state.drawerOpen,
    })
  }

  // This is where the component actually renders something (even null)
  render() {
    // The render function returns JSX
    return (
      <div>
        <div
          className={`about-drawer ${this.state.drawerOpen ? 'open' : ''}`}
          onClick={this.toggleDrawer}>
          <h1>About&nbsp;Project</h1>
          <p>
            Here we can have content about the jacket, links to anywhere else,
            logos of the partners etc.
          </p>
        </div>
      </div>
    )
  }
}

export default AboutOverlay
